<!-- 耕雲導師简历 -->

<template>
  <!-- 安详禅简介 -->
  <div class="contenBrief" v-html="content.news_content">
  
  </div>
</template>

<script>
  import { getCateList, getIntroduction } from "../../api/apis";
  export default {
    name: "",
    
    data() {
      return {
        content:''
      };
    },
    methods: {
     
    },
     created() {
        getCateList().then(res => {
            let id = res[0][5].id;
            getIntroduction(id).then(res => {
                this.content = res;
            });
        });
    }
  };
</script>

<style scoped>
 .contenBrief{
   line-height: 28px;
 }
</style>